/*
    TODO:
        - add dynamic reports (via antd Table)
        - add CSV download for those dynamic reports via react-csv: https://stackoverflow.com/questions/58687079/antd-table-export-to-csv-by-react-csv-make-table-slow
*/

import React, { Component } from 'react';
import { Container } from 'bootstrap-4-react';
import { Auth, API } from 'aws-amplify';
import { ReportsPage } from '../pages';
//import { Logger } from 'aws-amplify';



//const logger = new Logger('Main');
const apiName = 'xsquare customers';
const path = '/ride';


export default class Main extends Component {
    
    constructor(props) {
      
        super(props);

        this.handler = this.handler.bind(this);
    }


    downloadFile() {

        Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            const options = {
                headers: {
                    Authorization: jwtToken
                }
            }
            API.post(apiName, path, options).then(response => {
                //console.log(response.download_url);
                window.open(response.download_url, "_self");
            }).catch(error => {
                console.log(error)
                // TODO: show error message
            });
        }).catch(error => {
            // usually when user is "Not Authenticated" yet
            console.log(error)
            // TODO: show error message
        });
    }

    
    handler(event) {
        event.preventDefault();
        this.downloadFile();
    }    
    
    

    render() {
      
        console.log("Reports.render");

        return (
        
          <Container as="main" role="main">
            <div className="starter-template">
                <ReportsPage user={this.props.state} handler={this.handler} />
            </div>
          </Container>
        )
    }
}
