import React, { Component } from 'react';
import { Container } from 'bootstrap-4-react';


export default class NoMatch extends Component {
        

    render() {
        console.log("NoMatch.render");
        return (
          <Container as="main" role="main">
            <div className="starter-template">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            <h2>No Match</h2>
            </div>
          </Container>
        )
    }
}
