import React, { Component } from 'react';


export default class ReportsPage extends Component {

    
  render() {
    
    //console.log("ReportsPage.render");
    
    if (null == this.props.user) {
        return null
    }
    
    return (
        <React.Fragment>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div>
                <header>                    
                    <a className="App-link" target="_blank" rel="noopener noreferrer" href="#" onClick={this.props.handler}>Download AGV data export</a>
                </header>
            </div>
        </React.Fragment>
    )
  }
}
