import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import {
 CartesianGrid,
 //AreaChart,
 //Area,
 XAxis,
 YAxis,
 Tooltip,
 ResponsiveContainer,
 Legend,
 //BarChart,
 //Bar,
 LineChart,
 Line,
 ReferenceLine,
 Label,
 Brush
} from "recharts";
import moment from "moment";
import numeral from "numeral";
import Chart from "./Chart.js";
import {getTimezone} from 'countries-and-timezones';
import { Button, ButtonGroup } from 'reactstrap';
import { useState } from "react";
import { Link } from "react-router-dom"

const numberFormatter = item => numeral(item).format("0,0");
const dateFormatter = item => moment(item).format("MMMDD");
const hourFormatter = item => moment(item).format("HH:00");

const renderSingleValue = (resultSet, key) => (
 <h1 height={300}>{numberFormatter(resultSet.chartPivot()[0][key])}</h1>
);

// helper to count the number of rows in a result without filling in missing timestamps.
// Used to count active hours in a day
const renderCount = (resultSet, key) => (
 <h1 height={300}>{numberFormatter(resultSet.pivot({fillMissingDates:false}).length)}</h1>
);

// right: 50 => give the right side label of the Brush a bit room to render
const CartesianChart = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={350}>
    <ChartComponent data={resultSet.chartPivot()} margin={{ top: 5, right: 50, left: 0, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      { children }
      <Legend />
    </ChartComponent>
  </ResponsiveContainer>
)

const colors = ['#FF6492', '#141446', '#7A77FF', '#FF0000'];

//const label_transport_orders = "Transport Orders";
const label_transport_orders = "Completed Trips";

// TODO: add drop down to let the user select timezone (or add a user profile section where the user can configure the preferred timezone globally)
let timezone = 'Asia/Singapore';
//console.log(getTimezone(timezone));
let the_filter = [];
let the_filter2 = [];

function setFilter(filter) {
    console.log('filter:' + filter);
    
    // default: all missions
    the_filter = []
    the_filter2 = []
    
    if (filter == 'big') {
        the_filter = [
                  {
                    dimension: "FactAgvActivities.mission",
                    operator: "contains",
                    values: ["FIBC"]
                  }         
        ];
        the_filter2 = [
                  {
                    dimension: "FactAgvActivities2.mission",
                    operator: "contains",
                    values: ["FIBC"]
                  }         
        ];
    }

    if (filter == 'small') {
        the_filter = [
                  {
                    dimension: "FactAgvActivities.mission",
                    operator: "notContains",
                    values: ["FIBC"]
                  }         
        ];
        the_filter2 = [
                  {
                    dimension: "FactAgvActivities2.mission",
                    operator: "notContains",
                    values: ["FIBC"]
                  }         
        ];
    }
}

class Dashboard extends Component {
    
    constructor(props) {
      
        super(props);
        //console.log(props.filter);
        setFilter(props.filter);
    }
    
    render() {
 
        return (
            
            <React.Fragment>
                <Container fluid>

                    <Row>
                    
                     <Col sm="3">
                        <Link as={Col} to="/all" className="btn">All</Link>
                        <Link as={Col} to="/small" className="btn">Small Bags</Link>
                        <Link as={Col} to="/big" className="btn">Big Bags</Link>
                     </Col>
                    </Row>

                    <Row>
                    
                     <Col sm="3">
                       <Chart
                         title={label_transport_orders + " Today"}
                        query={{
                            renewQuery: true,
                            timezone: timezone,
                            measures: ["FactAgvActivities.count"],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities.eventTimestamp",
                                dateRange: "Today"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ].concat(the_filter)
                        }}
                        render={resultSet => renderSingleValue(resultSet, "FactAgvActivities.count")}
                       />
                     </Col>
                     
                     <Col sm="3">
                       <Chart
                         title="AGV Active Hours Today"
                        query={{
                            renewQuery: true,
                            timezone: timezone,
                            measures: ["FactAgvActivities.count"],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities.eventTimestamp",
                                granularity: "hour",
                                dateRange: "Today"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ].concat(the_filter)
                        }}
                        render={resultSet => renderCount(resultSet, "FactAgvActivities.count")}
                       />
                     </Col>
                     
                     <Col sm="3">
                       <Chart
                         title={label_transport_orders + " This Month" }
                        query={{
                            renewQuery: true,
                            timezone: timezone,
                            measures: ["FactAgvActivities.count"],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities.eventTimestamp",
                                dateRange: "This Month"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ].concat(the_filter)
                        }}
                        render={resultSet => renderSingleValue(resultSet, "FactAgvActivities.count")}
                       />
                     </Col>
                     
                     <Col sm="3">
                       <Chart
                         title={label_transport_orders + " All Time" }
                        query={{
                            renewQuery: true,
                            timezone: timezone,
                            measures: ["FactAgvActivities.count"],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities.eventTimestamp"
                                //, dateRange: "This Month"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ].concat(the_filter)
                        }}
                        render={resultSet => renderSingleValue(resultSet, "FactAgvActivities.count")}
                       />
                     </Col>
                                          
                    </Row>
                    <br />
                    <br />
                    <Row>
                     <Col sm="6">
                       <Chart
                         title={label_transport_orders + " Per Hour, Today"}
                         query={{
                            renewQuery: true,
                            timezone: timezone,
                            measures: ["FactAgvActivities.count"],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities.eventTimestamp",
                                granularity: "hour",
                                dateRange: "Today"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ].concat(the_filter)
                        }}
                         render={resultSet => (
                           <ResponsiveContainer width="100%" height={350}>
                             <LineChart data={resultSet.chartPivot()} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                               <XAxis dataKey="category" tickFormatter={hourFormatter} />
                               <YAxis tickFormatter={numberFormatter} />
                               <Tooltip labelFormatter={hourFormatter} />
                               <Line
                                 dataKey="FactAgvActivities.count"
                                 name={label_transport_orders}
                                 stroke="rgb(106, 110, 229)"
                                 fill="rgba(106, 110, 229, .16)"
                               />
                             </LineChart>
                           </ResponsiveContainer>
                         )}
                       />
                     </Col>
                     <Col sm="6">
                       <Chart
                         title={label_transport_orders + " Per Shift, Last 90 Days"}
                         
                        query={{
                            renewQuery: true,
                            
                            //TODO: fix query. right now it works because the shifts are exactly 8h off midnight which coincides with UTC. should their shift start at 9am or 7am this breaks
                            //timezone: timezone,
                            
                            measures: [
                                "FactAgvActivities2.dayshift",
                                "FactAgvActivities2.nightshift",
                                "FactAgvActivities2.total"
                                //, "FactAgvActivities2.target"
                            ],
                            timeDimensions: [
                            {
                                dimension: "FactAgvActivities2.shiftTimestamp",
                                granularity: "day"
                                //, dateRange: "from 10 days ago to now"
                                , dateRange: "last 90 days"
                            }
                            ],
                            filters: [
                            {
                                dimension: "FactAgvActivities2.status",
                                operator: "equals",
                                values: [
                                  "completed"
                                ]
                            }
                            ].concat(the_filter2),
                            dimensions: []
                        }}

                        
                         render={resultSet => (
                          //console.log(resultSet),
                           <ResponsiveContainer width="100%" height={350}>
                           
                          <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
                               <XAxis dataKey="category" tickFormatter={dateFormatter} />,
                               
                               
                               <Tooltip labelFormatter={dateFormatter} />,
                               
                              
                              <YAxis type='number' key="" />
                              
                              // Brush is our date range selector. startIndex={76} => date range select from db is last 90 days so 
                              // 76 means we are displaying the last 14 days by default
                              // dataKey="category" => not sure why but we get the real datetime using this. without we would only see an index
                              <Brush dataKey="category" startIndex={76} height={30} stroke="#8884d8" tickFormatter={dateFormatter} />
                              
                              <ReferenceLine y={205} stroke="red" strokeWidth="3">
                                <Label value="Target" offset={10} position="insideBottom" />
                              </ReferenceLine>
                              
                              {resultSet.seriesNames().map((series, i) => (
                              //console.log(series),

                               <Line
                                  key={series.key}
                                  stackId={i}
                                  dataKey={series.key}
                                  name={series.title.split(' ')[1]}
                                  stroke={colors[i]}
                                  strokeWidth={series.key=="FactAgvActivities2.target"?"3":"1"}
                                  dot={series.key=="FactAgvActivities2.target"?false:true}
                                />
                              ))}
                          </CartesianChart>                           
                           </ResponsiveContainer>
                         )}
                        />
                     </Col>                           
                     
                    </Row>
                    
                    <br />
                    <br />
                    
                    <Row>
                     <Col sm="6">
                       <Chart
                         title="AGV Active Hours Per Day, Last 14 Days" 
                         
                         query={{
                            renewQuery: true,
                            timezone: timezone,
                            measures: [
                            "FactAgvActivities2.activehours"
                            ],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities2.eventTimestamp",
                                granularity: "day",
                                dateRange: "last 14 days"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities2.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ].concat(the_filter2)
                        }}
                         render={resultSet => (
                           <ResponsiveContainer width="100%" height={350}>
                             <LineChart data={resultSet.chartPivot()} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                               <XAxis dataKey="category" tickFormatter={dateFormatter} />
                               <YAxis tickFormatter={numberFormatter} />
                               <Tooltip labelFormatter={dateFormatter} />
                               <Line
                                 dataKey="FactAgvActivities2.activehours"
                                 name="Active Hours"
                                 stroke="rgb(106, 110, 229)"
                                 fill="rgba(106, 110, 229, .16)"
                               />
                             </LineChart>
                           </ResponsiveContainer>
                         )}
                       />
                     </Col>
                     
                     <Col sm="6">
                       <Chart
                         title="Average Trips/Active Hour Per Day, Last 14 Days" 
                         
                         query={{
                            renewQuery: true,
                            timezone: timezone,
                            measures: [
                            "FactAgvActivities2.ordersperhour"
                            ],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities2.eventTimestamp",
                                granularity: "day",
                                dateRange: "last 14 days"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities2.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ].concat(the_filter2)
                        }}
                         render={resultSet => (
                           <ResponsiveContainer width="100%" height={350}>
                             <LineChart data={resultSet.chartPivot()} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                               <XAxis dataKey="category" tickFormatter={dateFormatter} />
                               <YAxis tickFormatter={numberFormatter} />
                               <Tooltip labelFormatter={dateFormatter} />
                               <Line
                                 dataKey="FactAgvActivities2.ordersperhour"
                                 name="Average Trips/Active Hour"
                                 stroke="rgb(106, 110, 229)"
                                 fill="rgba(106, 110, 229, .16)"
                               />
                             </LineChart>
                           </ResponsiveContainer>
                         )}
                       />
                     </Col>
                     
                    </Row>
                
                                        
                    <br />
                    <br />       
                    <Row>
                     <Col sm="12">
                     All times are in GMT{getTimezone(timezone).utcOffsetStr} ({timezone})
                     </Col>       
                    </Row>

                </Container>
            </React.Fragment>
        );
    }
}

                        /*
                         query={{
                            renewQuery: true,
                            //TODO: fix query. right now it works because the shifts are exactly 8h off midnight which coincides with UTC. should their shift start at 9am or 7am this breaks
                            //timezone: timezone,
                            measures: ["FactAgvActivities2.count"],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities2.shiftTimestamp",
                                granularity: "day"
                                ,dateRange: "from 14 days ago to now"
                              }
                            ],
                            dimensions: [
                                "FactAgvActivities2.shift"
                            ],                            
                            filters: [
                              {
                                dimension: "FactAgvActivities2.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ]
                        }}*/

                     /*
                     <Col sm="6">
                       <Chart
                         title="Transport Orders Per Day, Last 7 Days"
                         query={{
                            //no need to disable caching here as data won't change anymore, can use full caching renewQuery: true,
                            timezone: timezone,
                            measures: ["FactAgvActivities.count"],
                            timeDimensions: [
                              {
                                dimension: "FactAgvActivities.eventTimestamp",
                                granularity: "day",
                                dateRange: "from 6 days ago to now"
                              }
                            ],
                            filters: [
                              {
                                dimension: "FactAgvActivities.status",
                                operator: "equals",
                                values: ["completed"]
                              }
                            ]
                        }}
                         render={resultSet => (
                           <ResponsiveContainer width="100%" height={300}>
                             <LineChart data={resultSet.chartPivot()}>
                               <XAxis dataKey="category" tickFormatter={dateFormatter} />
                               <YAxis tickFormatter={numberFormatter} />
                               <Tooltip labelFormatter={dateFormatter} />
                               <Line
                                 dataKey="FactAgvActivities.count"
                                 name="Orders"
                                 stroke="rgb(106, 110, 229)"
                                 fill="rgba(106, 110, 229, .16)"
                               />
                             </LineChart>
                           </ResponsiveContainer>
                         )}
                       />
                     </Col>      
                     */


export default Dashboard;