import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react'
import {BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Navigator, NoMatch, Reports, Dashboard } from './components';
import Amplify, { Auth, Hub } from "aws-amplify";
import { Switch } from 'react-router-dom';
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";


//"https://vozko2kssc.execute-api.ap-southeast-1.amazonaws.com/prod"
const API_ENDPOINT = "https://api.xsquareportal.com";
const CUBEJS_API = API_ENDPOINT + "/cubejs";



// REF: https://cube.dev/docs/security
/*
const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: `${API_URL}/cubejs-api/v1`
});
*/
const cubejsApi = cubejs(async () => {
    
    let user = await Auth.currentAuthenticatedUser();
    let idToken = user.signInUserSession.idToken.jwtToken;
    
    //console.log(user);
    
    return idToken;
}, 
{
  apiUrl: `${CUBEJS_API}/cubejs-api/v1`
});


//import awsExports from "./aws-exports";
//import awsExports from "./xsquare-config";
//Amplify.configure(awsExports);

Amplify.configure({
    // OPTIONAL - if your API requires authentication
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // REQUIRED - Amazon Cognito Region
        region: "ap-southeast-1",
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: "ap-southeast-1_zn1gmcBlF",
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: "nat6n1mebgqsho5kfq470vbf0",
    },
    API: {
        endpoints: [
            {
                name: "xsquare customers",
                endpoint: API_ENDPOINT
		//endpoint: "https://api.xsquareportal.com/prod"
            }
        ]
    }
});


class App extends Component {

    constructor(props){
        
        super(props)
        
        //console.log("App.constructor");

        this.state = { user: null, redirect: null }

        Hub.listen('auth', (data) => {
            const { payload } = data;
            this.onAuthEvent(payload);           
        })
    }

  
    componentDidMount() {
        //console.log("App.componentDidMount");    
        this.loadUser();
    }

    loadUser() {
        //console.log("App.loadUser");
        Auth.currentAuthenticatedUser()
        .then(user => this.setState({ user: user }))
        .catch(err => this.setState({ user: null }));
    }

    onAuthEvent(payload) {
        this.loadUser();
        if ( payload.event === "signOut" ) {
            // Force a reload of the whole App, otherwise the log-in screen doesn't show up
            window.location.href="/";
        }
    }

    
    render() {
        
        // first call to render or when logged out will have no user yet in state. ignore, 
        // Amplify/React will display the login UI automatically
        if ( null == this.state.user ) {
            // TODO: at the moment we  are using the built-in UI for login. Suppress rendering anything when not logged in 
            // as MS Edge will not display things properly otherwise
            return null
        }
  
        //console.log("App.render");
        //console.log(this.state.user);

        return (
                    
            <CubeProvider cubejsApi={cubejsApi}>
                       
                  <Router>
                    
                    <Navigator state={this.state} />
                    
                        
                    <Switch>

                        {/* REF: https://tylermcginnis.com/react-router-pass-props-to-components/ */}
                        <Route
                            exact
                            path="/Reports" 
                            render={(props) => <Reports {...props} state={this.state} />}
                        />

                        <Route
                            exact
                            path="/small" 
                            component={(props) => <Dashboard timestamp={new Date().toString()} {...props} state={this.state} filter='small' />}
                        />

                        <Route
                            exact
                            path="/big" 
                            component={(props) => <Dashboard timestamp={new Date().toString()} {...props} state={this.state} filter='big' />}
                        />


                        <Route
                            exact
                            path="/all" 
                            component={(props) => <Dashboard timestamp={new Date().toString()} {...props} state={this.state} filter='all' />}
                        />
                        
                        {/* If none of the above routes match, redirect back to the main page (dashboard) */}
                        <Redirect
                            to={{
                                pathname: "/all"
                            }}
                        />
                      
                    </Switch>
                    
                  </Router>
                  
            </CubeProvider>
        );
    }
}

// TODO: modify look/feel of the default Auth UI:
//  https://docs.amplify.aws/lib/auth/customui/q/platform/js
export default withAuthenticator(App, true);

