import React, { Component } from 'react';
import { Navbar, Nav } from 'bootstrap-4-react';
//import { Button } from 'bootstrap-4-react';
import { SignOut } from 'aws-amplify-react';
import { LinkContainer } from "react-router-bootstrap";
//import { Logger } from 'aws-amplify';



// REF: https://serverless-stack.com/chapters/adding-links-in-the-navbar.html
//      https://serverless-stack.com/chapters/handle-routes-with-react-router.html
// In summary: need react-router-bootstrap to use LinkContainer to wrap our links in order to utilize client-side routing
// Without it, the bootstrap-react routing will always use external URLs (refresh from server) which we don't want/doesn't work with Apmplify.
// For Amplify we need a single-page react app that routes only within itself.
const NaviItems = props => (
  <React.Fragment>
    <LinkContainer to="/">
        <Nav.ItemLink active>Dashboard</Nav.ItemLink>
    </LinkContainer>    
    <LinkContainer to="/Reports">
        <Nav.ItemLink active>Reports</Nav.ItemLink>
    </LinkContainer>    
  </React.Fragment>

)
const NaviItems2 = props => (
  <React.Fragment>
    <LinkContainer to="/all">
        <Nav.ItemLink active>All</Nav.ItemLink>
    </LinkContainer>    
    <LinkContainer to="/small">
        <Nav.ItemLink active>Small Bags</Nav.ItemLink>
    </LinkContainer>    
    <LinkContainer to="/big">
        <Nav.ItemLink active>Big Bags</Nav.ItemLink>
    </LinkContainer>    
  </React.Fragment>

)
//const logger = new Logger('Navigator');


export default class Navigator extends Component {
        
    render() {
      
        const { user } = this.props.state;

        // REF: https://react-bootstrap.github.io/components/navbar/    
        return (
        <div>
          <Navbar expand="md" dark bg="dark">
            <Navbar.Toggler target="#navbarsExampleDefault" />

            <Navbar.Collapse id="navbarsExampleDefault">
              <Navbar.Nav mr="auto">
                  <NaviItems />
              </Navbar.Nav>
              <Navbar.Text mr="2">
                { user? 'Logged in as: ' + user.username : 'Please sign in' }
              </Navbar.Text>
              { user && <SignOut /> }
            </Navbar.Collapse>
          </Navbar>
          </div>
          )
          /*
          <Navbar expand="md" white bg="white">
            <Navbar.Collapse>
              <Navbar.Nav mr="auto">
                  <NaviItems2 />
              </Navbar.Nav>
            </Navbar.Collapse>
          </Navbar>
            */
        /*
        <React.Fragment>
            <Button variant="secondary" onClick={this.handleClick}>Sign Out</Button>{' '}
        </React.Fragment>            

        */
  }
}
